import React from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap';
import '../../Style/footer.css';



import aboutImg from '../../assets/all-images/logo1-removebg-preview.png'




const quickLinks = [
  {
    path: '/about',
    display: 'About'
  },
  {
    path: '/privacy',
    display: 'Privacy Policy'
  },
  {
    path: '/term',
    display: 'Term & Condition'
  },
  {
    path: '/cars',
    display: 'Car Listing'
  },
  {
    path: '/onewaydrop',
    display: 'One Way Drop'
  },
  {
    path: "/blogs",
    display: "Blog",
  },
  {
    path: '/contact',
    display: 'Contact'
  },

]

const quickLinks1 = [
  {
    path: '/car-rental-in-karachi-pakistan',
    display: 'Car Rental karachi Pakistan'
  },

  {
    path: '/rent-a-car-in-karachi',
    display: 'Rent a Car in karachi'
  },
  {
    path: '/rent-a-car-in-Faisalabad',
    display: 'Rent a Car in Faisalabad'
  },
  {
    path: '/rent-a-car-in-Gujranwala',
    display: 'Rent A Car In Gujranwala'
  },
  {
    path: "/rent-a-car-in-Islamabad",
    display: "Rent A Car In Islamabad",
  },
  {
    path: '/rent-a-car-in-Lahore',
    display: 'Rent A Car In Lahore'
  },
  {
    path: '/rent-a-car-in-Multan',
    display: 'Rent A Car In Multan'
  },
  {
    path: '/rent-a-car-in-Peshawar',
    display: 'Rent A Car In Peshawar'
  },
  {
    path: '/rent-a-car-in-Larkana',
    display: 'Rent A Car In Larkana'
  },
  {
    path: '/rent-a-car-in-Pakistan',
    display: 'Rent A Car In Pakistan'
  },

]

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  return <footer className = 'footer'>
    <Container>
      <Row>
        <Col lg='4' md='4' sm='12'>
        <div className='logo footer__logo'>
                <h1><Link to='/home' className='d-flex align-items-center gap-3'>
                {/* <i class="ri-car-line"></i>
                <span>Car Hire <br /> Service </span> */}
                <img src={aboutImg} alt="" style={{width: "213px",
    height: "115px"}}/>
                </Link></h1>
              </div>
              <p className="footer__logo-content">
              Welcome to <b>Rent a Car</b> , your premier choice for hassle-free car rental services all across Pakistan. At <b>Rent a Car</b> , we specialize in providing top-notch rental solutions with professional drivers, ensuring a comfortable and convenient journey for our valued customers.              </p>
        </Col>

        <Col lg='2' md='4' sm='6'>
          <div className='mb-4'>
              <h5 className="footer__link-title">
                Quick Links
              </h5>
              <ListGroup>
                {
                  quickLinks.map((item, index) => (
                    <ListGroupItem key = {index} className = 'p-0 mt-3 quick__link'> 
                    <Link to={item.path}>{item.display}</Link>
                         </ListGroupItem>
                  ))
                }
              </ListGroup>
          </div>
        </Col>
        <Col lg='3' md= '4' sm='6'>
              <div className="mb-4">
                 <h5 className="footer__link-title mb-4">
                Head Office
              </h5>
              <p className="office__info"> Plot # 04 Showroom number3, 7 block, opposite Sindh Green restaurant, behind Saima Presidency, Gulistan-e-Johar, Karachi, 75400
</p>
              <p className="office__info">Phone: <a href="tel:+92 340 222 1927" style={{color:"white"}}> +92 340 222 1927</a></p>
              <p className="office__info">Email: <a href="mailto:info@pakdreamrentacar.com" style={{color:"white"}}>info@pakdreamrentacar.com</a> </p>
              <p className="office__info">Office Time: 24/7 Hours</p>
              </div>
        </Col>
        <Col lg='3' md='4' sm='12'>
              <div className="mb-4">
              <h5 className="footer__link-title">
                Service Areas
              </h5>
              <ListGroup>
                {
                  quickLinks1.map((item, index) => (
                    <ListGroupItem key = {index} className = 'p-0 mt-3 quick__link'> 
                    <Link to={item.path}>{item.display}</Link>
                         </ListGroupItem>
                  ))
                }
              </ListGroup>
              </div>
        </Col>

        <Col lg='12'>
          <div className="footer__bottom">
            <p className="section__description d-flex align-items-center justify-content-center gap-1 pt-4">
            <i class="ri-copyright-line"></i> copyright {year}, Developed by <a href="http://www.webocreators.com" target="_blank" rel="noopener noreferrer">Webo Creators</a> . All rights reserved.
            </p>
          </div>
        
        </Col>
      </Row>
    </Container>
  </footer>
}

export default Footer;