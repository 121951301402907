

import React from 'react'
import { Link } from 'react-router-dom';
import { Col } from 'reactstrap';
import '../../Style/car-item.css';

const CarItem = ({item}) => {
    const {Product_Image_Upload, Product_Name,ac,petrol,cc,doors,person,luggage, Product_Price_Discounted} = item;
  return (
    <Col lg='4' md='4' sm='6' className='mb-5'>
    <div className="car__item">
        <div className="car__img">
            <img src={Product_Image_Upload} alt="" className='w-100' />
        </div>
        <div className="car__item-content mt-4">
            <h4 className="section__title text-center">{Product_Name}</h4>
            <h6 className="rent__price text-center mt-">Rs.{Product_Price_Discounted} <span>/Day</span></h6>
            <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
                    <span className='d-flex align-items-center gap-1'><i class="ri-car-line"></i>{ac ? "Ac Car" : "Non Ac"}</span>
                    <span className='d-flex align-items-center gap-1'><i class="ri-settings-2-line"></i>{person} Person</span>
                    <span className='d-flex align-items-center gap-1'><i class="ri-timer-flash-line"></i>{cc}cc</span>
            </div>
            <div className="car__item-info d-flex align-items-center justify-content-between mt-3 mb-4">
                    <span className='d-flex align-items-center gap-1'><i class="ri-car-line"></i>{petrol}</span>
                    <span className='d-flex align-items-center gap-1'><i class="ri-settings-2-line"></i>{luggage} Luggage</span>
                    <span className='d-flex align-items-center gap-1'><i class="ri-timer-flash-line"></i>{doors} Doors</span>
            </div>
            <button className="w-50 car__item-btn car__btn-rent">
                <a href="tel:+92 340 222 1927" style={{color:"white",textDecoration:"none"}}>Rent Now</a> 
            </button>
            <button className="w-50 car__item-btn car__btn-details">
                <Link to = {`/cars/${Product_Name}`}>Detail</Link>
            </button>
        </div>
    </div>
    </Col>
  )
}

export default CarItem