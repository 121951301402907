import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Form, FormGroup, Input, Row } from 'reactstrap'
import Helmet from '../components/Helmet/Helmet'
import CommonSection from '../components/UI/CommonSection'
import '../Style/contact.css';

const Contact = () => {

    const socialLinks = [
  {
    url: "#",
    icon: "ri-facebook-line",
  },
  {
    url: "#",
    icon: "ri-instagram-line",
  },
  {
    url: "#",
    icon: "ri-linkedin-line",
  },
  {
    url: "#",
    icon: "ri-twitter-line",
  },
];

  return (
    <Helmet title='Contact'>
        <CommonSection title='Contact'/>
        <div className="container banner-group-1">
               
               <div className='container' style={{background :  ""}}>
                 <br/>
            
            <h2 class="title mb-4 text-center"> <b>Our Offices</b> 

</h2>
                   <div class="row justify-content-center">
                       <div class="col-lg-4 col-sm-6">
                   
                   
                                 <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                               <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3> 
                   
                   
                              <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Karachi Customer Center
 
</h3>
       <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/>  <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel: +92 333 2221927"> +92 315 3800047</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                      <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /> <a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>

                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>     <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Lahore Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel:i+92 340 2221927">+92 340 2221927</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>  <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Islamabad Customer Center
 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel: +92 315 3800047"> +92 315 3800047</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                          <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a>
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>     <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Multan  Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel:i+92 340 2221927">+92 340 2221927</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                           <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3>    <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Hyderabad  Customer Center
 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}> <i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/><a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel: +92 315 3800047"> +92 315 3800047</a>
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>





                               </div>
                           </div>
                       </div>
                       <div class="col-lg-4 col-sm-6">
                           <div class="icon-box text-center cfg" style={{padding  :"30px 30px" ,height : "290px",color : "black", background : "white",borderBottom : "5px solid #000d6b",boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.21)",borderRadius: "15px"}} >
                             <h3 style={{textAlign:"left",color  :"white"}}>
                               <i className="ri-map-pin-line" style={{textAlign  : "left",padding: "11px 12px",borderRadius:"30px",
    background: "#000d6b",color  :"white",
    fontSize: "32px"}} />
                              </h3> 
                               <div class="icon-box-content">
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>Sukkar Customer Center

 
</h3>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}><i className="ri-phone-fill" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "29px"}}/> <a  style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}} href="tel:i+92 340 2221927">+92 340 2221927</a> 
 
</h3><hr style={{margin:"10px 0px"}}/>
                                   <h3 class="icon-box-title cgg" style={{fontWeight : "600",textAlign :  "left",fontSize : "22px"}}>                       <i className="ri-send-plane-line" style={{padding: "10px",
    color: "#000d6b",
    fontSize: "32px"}} /><a href="mailto:info@rentacarpd.com" style={{fontSize: "19px",fontWeight:"600",color :"black",textDecoration :"none"}}>info@rentacarpd.com</a> 
 
</h3>






                               </div>
                           </div>
                       </div>
                       </div>
                   

                     
</div>
</div>   
        <section>
            
            <Container>
                <Row>
                 
                    {/* <Col lg='12' md='12'>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d463078.9499964948!2d67.157711!3d24.938736!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33960427a30b3%3A0x1328b8436d530e27!2sRent%20A%20Car%20PD!5e0!3m2!1sen!2sus!4v1694645511499!5m2!1sen!2sus" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style={{border:" 0px"}}></iframe>
                    </Col> */}
                    <Col lg='7' md='7'>
                        <h6 className="fw-bold mb-4">Get In Touch </h6>
                        <Form>
                            <FormGroup className='contact__form'>
                                <Input placeholder='Your Name' type='text'/>
                                
                            </FormGroup>

                            <FormGroup className='contact__form'>
                                <Input placeholder='Email' type='email'/>
                                
                            </FormGroup>

                            <FormGroup className='contact__form'>
                               <textarea rows='5' placeholder='Message' className='textarea'></textarea>
                                
                            </FormGroup>

                            <button className='btn contact__btn' type='submit'>Send</button>
                        </Form>
                    </Col>
                    <Col lg='5' md='5'>
                        <div className="contact__info">
                            <h6 className="fw_bold"> Contact Information </h6>
                            <p className="section__description mb-0">Plot # 04 Showroom number3, 7 block, opposite Sindh Green restaurant, behind Saima Presidency, Gulistan-e-Johar, Karachi, 75400</p>
                            <div className="d-flex align-items-center gap-2">
                                <h6 className='fs-6 mb-0'>Phone: </h6>
                                <p className="section__description mb-0"><a href="tel:+92 340 222 1927" style={{color:"black"}}> +92 340 222 1927</a></p>
                            </div>

                            <div className="d-flex align-items-center gap-2">
                                <h6 className='fs-6 mb-0'>Email: </h6>
                                <p className="section__description mb-0"> <a href="mailto:info@rentacarpd.com" style={{color:"black"}}>info@rentacarpd.com</a></p>
                            </div>
                            <h6 className="fw-bold"> Follow Us</h6>
                            <div className="d-flex align-items-center gap-4 mt-3">
                                {
                                    socialLinks.map((item, index) => (
                                        <Link to={item.url} key={index} className="social__link-icon">
                                            <i class={item.icon}></i>
                                            </Link>
                                    ))
                                }
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>

    </Helmet>
  )
}

export default Contact